.single-article {
    position: relative;
  }
  
  .single-article-special .img-show-hover {
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .single-article-special:hover .img-show-hover,
  .single-article-special.hover .img-show-hover {
    display: block;
  }
  
  .single-article-special:hover .img-fluid,
  .single-article-special.hover .img-fluid {
    display: none;
  }
  

  .img-scale {
    object-fit: scale-down;
    transform: none !important;
    transition: none !important;
  }

  .article-category a {
    margin: 0 5px;
  }

  .article-category a:first-child{
    margin: 0;
  }

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px; /* Maximum width for the video */
    margin: 0 auto; /* Center the video container */
    padding: 20px 0; /* Add padding around the video */
  }
  
  .video-container video {
    width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
  }